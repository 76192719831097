export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            hostelAdmissions: [],
            memberId: null,
            students: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        hostelAdmissions(state) {
            return state.hostelAdmissions.map((item, sl) => {
                item.sl = sl + 1;
                item.display_name = `${item.code} - ${item.name}`;
                return item;
            });
        },

        memberId(state) {
            return state.memberId;
        },

        students(state) {
            return state.students.map((item, sl) => {
                item.display_name = `${item.student.code} - ${item.student.name}`;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },

        setHostelAdmission(state, hostelAdmissions) {
            state.hostelAdmissions = hostelAdmissions;
        },

        NewMemberId(state, id) {
            state.memberId = id;
        },

        setStudent(state, student) {
            state.students = student
        }
    }, 

    actions: {
        async getHostelMemberId(context) {
            await axios.get(`${this.state.host}/get-hostel-admission-code`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('NewMemberId', res.data);
            })
            .catch(error => this.dispatch('snackbar/error', error))
        },

        async getHostelAdmissions(context, payload) {
            context.commit('setLoading', true);

            await axios.post(`${this.state.host}/get-hostel-members`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setHostelAdmission', res.data.hostelAdmissions);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveHostelAdmission(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-hostel-admission';
            } else {
                url = 'add-hostel-admission';
                delete payload.id;
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getHostelAdmissions', {status: 'a'});
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async saveCheckout(context, payload) {
            
            let isSuccess = false;

            await axios.post(`${this.state.host}/hostel-checkout`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getHostelAdmissions', {status: 'a'});
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteHostelAdmission(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-hostel-admission/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getHostelAdmissions', {status: 'a'});
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getHostelStudent(context) {
            context.commit('setLoading', true);
            
            await axios.get(`${this.state.host}/get-hostel-student`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setStudent', res.data.student);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        }
    }
}