export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            positions: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        positions(state) {
            return state.positions.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setPosition(state, positions) {
            state.positions = positions
        },
    },

    actions: {
        async getPositions(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-positions`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setPosition', res.data.positions);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async savePosition(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-position';
            } else {
                url = 'add-position';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getPositions');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deletePosition(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-position/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getPositions');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
    }
}
