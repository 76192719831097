export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            accounts: [],
            transactions: [],
            banks: [],
            bankTransfers: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        accounts(state) {
            return state.accounts.map((item, sl) => {
                item.sl = sl + 1;
                item.display_name = `${item.account_name} - ${item.account_number} - ${item.bank_name}`;
                return item;
            });
        },

        transactions(state) {
            return state.transactions.map((item, sl) => {
                item.sl = sl + 1;
                item.deposit = item.type == 'Deposit' ? item.amount : 0.00;
                item.withdraw = item.type == 'Withdraw' ? item.amount : 0.00;
                return item;
            })
        },

        banks(state) {
            return state.banks;
        },

        bankTransfers(state) {
            return state.bankTransfers.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setAccount(state, accounts) {
            state.accounts = accounts
        },

        setTransaction(state, transactions) {
            state.transactions = transactions
        },

        setBank(state, bank) {
            state.banks = bank
        },

        setBankTransfer(state, transfer) {
            state.bankTransfers = transfer
        }
    },

    actions: {
        async getAccounts(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-bank-accounts`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setAccount', res.data.accounts);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveAccount(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-bank-account';
            } else {
                url = 'add-bank-account';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getAccounts');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteAccount(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-bank-account/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getAccounts');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async generateCode(context) {
            let code = await axios.get(`${this.state.host}/get-bank-transaction-code`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
            return code;
        },

        async getTransactions(context, payload) {
            await axios.post(`${this.state.host}/get-bank-transactions`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setTransaction', res.data.transactions);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveTransaction(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-bank-transaction';
            } else {
                url = 'add-bank-transaction';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteTransaction(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-bank-transaction/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getBankTransfer(context, payload) {
            await axios.post(`${this.state.host}/get-bank-transfers`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setBankTransfer', res.data.transfers);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveBankTransfer(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-bank-transfer';
            } else {
                url = 'add-bank-transfer';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteBankTransfer(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-bank-transfer/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async currentBankBalance(context, payload) {
            await axios.post(`${this.state.host}/get-current-bank-balance`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setBank', res.data.banks);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getBankLedger(context, payload) {
            let ledger = await axios.post(`${this.state.host}/get-bank-ledger`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.ledger;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return ledger;
        }
    }
}
