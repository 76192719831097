export default {
    namespaced: true,

    state: () => ({
        menuItems: [
            {
                name: "Dashboard",
                icon: "mdi-apps",
                link: "/",
                color: "brown",
                show: false,
                locked: true,
                id: "difw9l",
            },
            {
                name: "Student Module",
                icon: "mdi-human-child",
                color: "light-green",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Admission Entry",
                        link: "/student",
                        color: "blue",
                        id: "nbpu5",
                        icon: "mdi-human-child",
                    },
                    {
                        name: "Student List",
                        link: "/students",
                        color: "blue",
                        id: "vba2p3",
                        icon: "mdi-format-list-bulleted",
                    },
                    {
                        name: "Promotion Entry",
                        link: "/promotion",
                        color: "blue",
                        id: "vb02p3",
                        icon: "mdi-chevron-triple-up",
                    },
                    {
                        name: "Attendance Entry",
                        link: "/attendance",
                        color: "blue",
                        id: "efnnlyk",
                        icon: "mdi-bell-ring",
                    },
                    {
                        name: "TC Entry",
                        link: "/tc-entry",
                        color: "blue",
                        id: "pvxqf",
                        icon: "mdi-transit-transfer",
                    },
                    {
                        name: "Student Id Card",
                        link: "/student-id-card",
                        color: "blue",
                        id: "psxid",
                        icon: "mdi-cart",
                    },
                    {
                        name: "Class Routine",
                        link: "/class-routine",
                        color: "blue",
                        id: "ps5xid",
                        icon: "mdi-cart",
                    },
                    {
                        name: "Alumni Entry",
                        link: "/alumni",
                        color: "blue",
                        id: "ps5x8id",
                        icon: "mdi-cart",
                    },
                    {
                        name: "Reports",
                        icon: "mdi-file",
                        link: "/",
                        color: "blue",
                        locked: true,
                        children: [
                            {
                                name: "Student Record",
                                link: "/student-record",
                                color: "blue",
                                id: "k4roq",
                                icon: "mdi-format-list-checks",
                            },
                            {
                                name: "Delete Student Record",
                                link: "/delete-student-record",
                                color: "blue",
                                id: "k45roq",
                                icon: "mdi-trash-can-outline",
                            },
                            {
                                name: "Guardians Record",
                                link: "/Gurdians",
                                color: "blue",
                                id: "ytlw2",
                                icon: "mdi-human-male-boy",
                            },
                            {
                                name: "Parents Record",
                                link: "/Parents",
                                color: "blue",
                                id: "kahlvt5",
                                icon: "mdi-human-male-female-child",
                            },
                            {
                                name: "Alumni Record",
                                link: "/alumni-record",
                                color: "blue",
                                id: "ka51hlvt5",
                                icon: "mdi-human-male-female-child",
                            },
                            {
                                name: "Attendance Records",
                                link: "/attendance-record",
                                color: "blue",
                                id: "8hlvt5",
                                icon: "mdi-car-light-dimmed",
                            },
                            {
                                name: "Promotion Report",
                                link: "/promotion-report",
                                color: "blue",
                                id: "66lvt5",
                                icon: "mdi-transfer-right",
                            },
                            {
                                name: "TC Record",
                                link: "/tc-record",
                                color: "blue",
                                id: "96lvt5",
                                icon: "mdi-playlist-remove",
                            },
                        ],
                        id: "95yvfon",
                    },
                ],
                id: "qowpep",
            },
            {
                name: "Employee Module",
                icon: "mdi-account-group",
                color: "lime darken-2",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Employee Entry",
                        link: "/employee",
                        color: "lime darken-2",
                        id: "5eak3n",
                        icon: "mdi-account",
                    },
                    {
                        name: "Employee List",
                        link: "/employees",
                        color: "lime darken-2",
                        id: "evqyzr",
                        icon: "mdi-format-list-bulleted",
                    },
                    {
                        name: "Ex-Employee List",
                        link: "/ex-employee-list",
                        color: "lime darken-2",
                        id: "evqyzx",
                        icon: "mdi-playlist-remove",
                    },
                    {
                        name: "Employee Type",
                        link: "/employee-type",
                        color: "lime darken-2",
                        id: "evqytzx",
                        icon: "mdi-ev-plug-type2",
                    },
                    {
                        name: "Employee ID Card",
                        link: "/employee-id-card",
                        color: "lime darken-2",
                        id: "evqy8tzx",
                        icon: "mdi-ev-plug-type2",
                    },
                    // {
                    //     name: "Attendance Entry",
                    //     link: "/",
                    //     color: "lime darken-2",
                    //     id: "bxl6bc",
                    //     icon: "mdi-account-clock",
                    // },
                    // {
                    //     name: "Leave Entry",
                    //     link: "/",
                    //     color: "lime darken-2",
                    //     id: "q37ap",
                    //     icon: "mdi-gate-arrow-left",
                    // },
                    // {
                    //     name: "Promotion Entry",
                    //     link: "/",
                    //     color: "lime darken-2",
                    //     id: "2ufl4",
                    //     icon: "mdi-account-clock",
                    // },
                    {
                        name: "Reports",
                        locked: true,
                        children: [
                            {
                                name: "Employee Record",
                                link: "/employee-record",
                                color: "lime darken-2",
                                id: "4q3ab",
                                icon: "mdi-clipboard-list",
                            },
                            {
                                name: "Attendance Record",
                                link: "/employee-attendances",
                                color: "lime darken-2",
                                id: "o6uiuae",
                                icon: "mdi-account-clock",
                            },
                            // {
                            //     name: "Leave Record",
                            //     link: "/",
                            //     color: "lime darken-2",
                            //     id: "1nc2vn",
                            //     icon: "mdi-account-clock",
                            // },
                        ],
                        color: "lime darken-2",
                        id: "tw8tv",
                        icon: "mdi-file-document",
                    },
                ],
                id: "cdnbkh",
            },
            {
                name: "Exam & Result Module",
                icon: "mdi-hammer-screwdriver",
                color: "orange",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Exam Head Entry",
                        link: "/exam-head",
                        color: "orange",
                        id: "n79ccl",
                        icon: "mdi-calendar-month-outline",
                    },
                    {
                        name: "Exam Entry",
                        link: "/exam",
                        color: "orange",
                        id: "n79ccl1",
                        icon: "mdi-cart-arrow-down",
                    },
                    {
                        name: "Exam Routine Entry",
                        link: "/exam-routine",
                        color: "orange",
                        id: "n79jsl1",
                        icon: "mdi-cart-arrow-down",
                    },
                    {
                        name: "Exam Routine",
                        link: "/exam-routines",
                        color: "orange",
                        id: "n79jsTl1",
                        icon: "mdi-cart-arrow-down",
                    },
                    {
                        name: "Admit Card",
                        link: "/admit-card",
                        color: "orange",
                        id: "n79c84l3",
                        icon: "mdi-cart-arrow-down",
                    },
                    {
                        name: "Result Entry",
                        link: "/exam-result",
                        color: "orange",
                        id: "n79ccl3",
                        icon: "mdi-cart-arrow-down",
                    },
                    {
                        name: "Result Publish",
                        link: "/result-publish",
                        color: "orange",
                        id: "vba2e4",
                        icon: "mdi-publish",
                    },
                    {
                        name: "Reports",
                        link: "/",
                        color: "orange",
                        id: "vntck1",
                        icon: "mdi-file",
                        locked: true,
                        children: [
                            // {
                            //     name: "Schedule Record",
                            //     link: "/",
                            //     color: "orange",
                            //     id: "fgpurRet",
                            //     icon: "mdi-account-tie",
                            // },
                            {
                                name: "Exam Record",
                                link: "/exams",
                                color: "orange",
                                id: "8520exf",
                                icon: "mdi-account-tie",
                            },
                            {
                                name: "Result Record",
                                link: "/result-record",
                                color: "orange",
                                id: "sR5et8",
                                icon: "mdi-account-tie",
                            },
                            {
                                name: "Mark Sheet",
                                link: "/mark-sheet",
                                color: "orange",
                                id: "sp4rRet",
                                icon: "mdi-clipboard-list",
                            },
                            {
                                name: "Tabulation Sheet",
                                link: "/tabulation-sheet",
                                color: "orange",
                                id: "v8ba2e4",
                                icon: "mdi-publish",
                            },
                        ],
                    },
                ],
                id: "oh8w8s",
            },
            {
                name: "Accounts Module",
                icon: "mdi-cash-register",
                link: "/",
                color: "pink",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Fees Collection",
                        link: "/fees-collection",
                        color: "pink",
                        id: "0syqgq",
                        icon: "mdi-rhombus-split",
                    },
                    {
                        name: "Advance Payment",
                        link: "/advance-payment",
                        color: "pink",
                        id: "0syqg978q",
                        icon: "mdi-rhombus-split",
                    },
                    {
                        name: "Salary Generate",
                        link: "/salary-generate",
                        color: "pink",
                        id: "0sy8gq",
                        icon: "mdi-cash-sync",
                    },
                    {
                        name: "Bonus Entry",
                        link: "/bonus",
                        color: "pink",
                        id: "0sy8g1q",
                        icon: "mdi-cash-sync",
                    },
                    {
                        name: "Cash Transaction",
                        link: "/cash-transaction",
                        color: "pink",
                        id: "25gox8",
                        icon: "mdi-cash",
                    },
                    {
                        name: "Bank Transaction",
                        link: "/bank-transaction",
                        color: "pink",
                        id: "b5bbps",
                        icon: "mdi-bank",
                    },
                    {
                        name: "Bank Transfer",
                        link: "/bank-transfer",
                        color: "pink",
                        id: "b5bj1bps",
                        icon: "mdi-bank",
                    },
                    {
                        name: "Supplier Payment",
                        link: "/supplier-payment",
                        color: "pink",
                        id: "xy3ir",
                        icon: "mdi-gender-transgender",
                    },
                    {
                        name: "Employee Payment",
                        link: "/employee-payment",
                        color: "pink",
                        id: "50tn6",
                        icon: "mdi-account-credit-card-outline",
                    },
                    {
                        name: "Collection Setting",
                        link: "/collection-setting",
                        color: "pink",
                        id: "b5bqps",
                        icon: "mdi-cash-register",
                    },
                    {
                        name: "Provident Fund",
                        icon: "mdi-file-account",
                        link: "/",
                        color: "pink",
                        locked: true,
                        children: [
                            {
                                name: "PF Transaction",
                                link: "/pf-transaction",
                                color: "pink",
                                id: "7q602xx",
                                icon: "mdi-bank-circle-outline",
                            },
                            {
                                name: "PF Transaction Record",
                                link: "/pf-transactions",
                                color: "pink",
                                id: "7q602xy",
                                icon: "mdi-bank-circle-outline",
                            },
                            {
                                name: "PF Balance",
                                link: "/pf-balance",
                                color: "pink",
                                id: "7q602yy",
                                icon: "mdi-bank-circle-outline",
                            },
                            {
                                name: "PF Ledger",
                                link: "/pf-ledger",
                                color: "pink",
                                id: "7q602hy",
                                icon: "mdi-bank-circle-outline",
                            },
                        ],
                        id: "t0k7pvv",
                    },
                    {
                        name: "Account Head",
                        icon: "mdi-account-tie",
                        link: "/",
                        color: "pink",
                        locked: true,
                        children: [
                            {
                                name: "Account Head Entry",
                                link: "/accounts",
                                color: "pink",
                                id: "v20cz",
                                icon: "mdi-account-tie",
                            },
                            {
                                name: "Bank Account Entry",
                                link: "/bank-account",
                                color: "pink",
                                id: "i8sznoh",
                                icon: "mdi-bank-circle-outline",
                            },
                        ],
                        id: "yj3v3tpm",
                    },
                    {
                        name: "Reports",
                        icon: "mdi-file-account",
                        link: "/",
                        color: "pink",
                        locked: true,
                        children: [
                            {
                                name: "Student Due List",
                                link: "/student-dues",
                                color: "pink",
                                id: "7q602x",
                                icon: "mdi-bank-circle-outline",
                            },
                            {
                                name: "Monthly Due List",
                                link: "/monthly-dues",
                                color: "pink",
                                id: "7q6025x",
                                icon: "mdi-bank-circle-outline",
                            },
                            {
                                name: "Collection Report",
                                link: "/colleciton-report",
                                color: "pink",
                                id: "7q612x",
                                icon: "mdi-crowd",
                            },
                            {
                                name: "Advance Payment Record",
                                link: "/advance-payments",
                                color: "pink",
                                id: "7q61j2x",
                                icon: "mdi-crowd",
                            },
                            {
                                name: "Headwise Expense Report",
                                link: "/colleciton-expense",
                                color: "pink",
                                id: "7q420x",
                                icon: "mdi-crowd",
                            },
                            {
                                name: "Transaction Report",
                                link: "/cash-transactions",
                                color: "pink",
                                id: "7q68x",
                                icon: "mdi-file-arrow-left-right-outline",
                            },
                            {
                                name: "Bank Transaction Report",
                                link: "/bank-transactions",
                                color: "pink",
                                id: "aoh0ra",
                                icon: "mdi-bank-transfer",
                            },
                            {
                                name: "Bank Transfer Report",
                                link: "/bank-transfers",
                                color: "pink",
                                id: "j7ra",
                                icon: "mdi-bank-transfer",
                            },
                            {
                                name: "Salary Generate Record",
                                link: "/salary-generate-record",
                                color: "pink",
                                id: "7q80wx",
                                icon: "mdi-cash-multiple",
                            },
                            {
                                name: "Salary Statement",
                                link: "/salary-statement",
                                color: "pink",
                                id: "julw8l",
                                icon: "mdi-factory",
                            },
                            {
                                name: "Bonus Records",
                                link: "/bonus-record",
                                color: "pink",
                                id: "jck1w8l",
                                icon: "mdi-factory",
                            },
                            {
                                name: "Employee Due List",
                                link: "/employee-due",
                                color: "pink",
                                id: "tcmkrv",
                                icon: "mdi-cash-register",
                            },
                            {
                                name: "Income & Expense Report",
                                link: "/income-expense-report",
                                color: "pink",
                                id: "7q909rx",
                                icon: "mdi-source-branch",
                            },
                            {
                                name: "Employee Payment Report",
                                link: "/employee-payments",
                                color: "pink",
                                id: "x0el4p",
                                icon: "mdi-account-cash-outline",
                            },
                            {
                                name: "Supplier Payment Report",
                                link: "/supplier-payments",
                                color: "pink",
                                id: "pqk5el",
                                icon: "mdi-file-swap",
                            },
                            {
                                name: "Cash Statement",
                                link: "/cash-statement",
                                color: "pink",
                                id: "qm3o9",
                                icon: "mdi-account-cash",
                            },
                            {
                                name: "Cash View",
                                link: "/cash-view",
                                color: "pink",
                                id: "q35o9",
                                icon: "mdi-account-cash",
                            },
                            {
                                name: "Bank Statement",
                                link: "/bank-statement",
                                color: "pink",
                                id: "jq35o9",
                                icon: "mdi-account-cash",
                            },
                            {
                                name: "Profit Loss",
                                link: "/profit-loss",
                                color: "pink",
                                id: "jq35Jo9",
                                icon: "mdi-account-cash",
                            },
                        ],
                        id: "t0k7pv",
                    },
                    
                ],
                id: "y8en5k",
            },
            {
                name: "Hostel Module",
                icon: "mdi-account-tie",
                link: "/",
                color: "cyan",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Hostel Monitor",
                        link: "/hostel-monitor",
                        color: "cyan",
                        id: "wmnm2",
                        icon: "mdi-account-tie",
                    },
                    {
                        name: "Hostel Admission",
                        link: "/hostel-admission",
                        color: "cyan",
                        id: "wfmn6",
                        icon: "mdi-account-tie",
                    },
                    {
                        name: "Hostel Entry",
                        link: "/hostel-entry",
                        color: "cyan",
                        id: "whnm3",
                        icon: "mdi-account-tie",
                    },
                    {
                        name: "Room Entry",
                        link: "/room-entry",
                        color: "cyan",
                        id: "wrnm4",
                        icon: "mdi-account-tie",
                    },
                    {
                        name: "Seat Entry",
                        link: "/seat-entry",
                        color: "cyan",
                        id: "wsnm5",
                        icon: "mdi-account-tie",
                    },
                    {
                        name: "Hostel Cash Transaction",
                        link: "/hostel-cash-transaction",
                        color: "cyan",
                        id: "wsnt3",
                        icon: "mdi-account-tie",
                    },
                    
                    {
                        name: "Reports",
                        icon: "mdi-file",
                        link: "/",
                        color: "cyan",
                        locked: true,
                        children: [
                            {
                                name: "Admission Record",
                                link: "/hostel-admission-record",
                                color: "cyan",
                                id: "cttfch",
                                icon: "mdi-account-tie",
                            },
                            // {
                            //     name: "Student Record",
                            //     link: "/",
                            //     color: "cyan",
                            //     id: "v2r50cz",
                            //     icon: "mdi-account-tie",
                            // },
                            // {
                            //     name: "Hostel Record",
                            //     link: "/administration/settings/areas",
                            //     color: "cyan",
                            //     id: "i8s86noh",
                            //     icon: "mdi-account-tie",
                            // },
                            {
                                name: "Room Record",
                                link: "/hostel-room-record",
                                color: "teal",
                                id: "frj2og",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Seat Record",
                                link: "/hostel-seat-record",
                                color: "teal",
                                id: "1yssas",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Cash Transaction Record",
                                link: "/hostel-cash-transaction-record",
                                color: "teal",
                                id: "w5oztk",
                                icon: "mdi-warehouse",
                            }
                        ],
                        id: "yj33tpm",
                    }
                ],
                id: "6cegai",
            },
            {
                name: "Library Module",
                icon: "mdi-library",
                link: "/",
                color: "teal",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Library Monitor",
                        link: "/library-monitor",
                        color: "teal",
                        id: "n9d6dm",
                        icon: "mdi-warehouse",
                    },
                    {
                        name: "Library Member Entry",
                        link: "/member-entry",
                        color: "teal",
                        id: "n9d6dl",
                        icon: "mdi-warehouse",
                    },
                    {
                        name: "Book Entry",
                        link: "/book-entry",
                        color: "teal",
                        id: "kfgkfs",
                        icon: "mdi-warehouse",
                    },
                    {
                        name: "Book Issue Entry",
                        link: "/book-issue",
                        color: "teal",
                        id: "dx4lt",
                        icon: "mdi-warehouse",
                    },
                    {
                        name: "Book Management",
                        link: "/book-management",
                        color: "teal",
                        id: "6ouuvp",
                        icon: "mdi-warehouse",
                    },
                    
                    // {
                    //     name: "Libbrary Stuff Entry",
                    //     link: "/",
                    //     color: "teal",
                    //     id: "7uvamruq",
                    //     icon: "mdi-warehouse",
                    // },

                    {
                        name: "Settings",
                        icon: "mdi-cog-outline",
                        link: "/",
                        color: "teal",
                        locked: true,
                        children: [
                            {
                                name: "Book Shelf",
                                link: "/book-shelf",
                                color: "teal",
                                id: "7uvamlus",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Library Subject",
                                link: "/library-subject",
                                color: "teal",
                                id: "7uvxmlzs",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Book Category",
                                link: "/book-category",
                                color: "teal",
                                id: "7uvxm7lzs",
                                icon: "mdi-warehouse",
                            }
                        ],
                        id: "noj5sm",
                    },
                    
                    {
                        name: "Reports",
                        icon: "mdi-file",
                        link: "/",
                        color: "teal",
                        locked: true,
                        children: [
                            {
                                name: "Member Record",
                                link: "/library-member-record",
                                color: "teal",
                                id: "k5kqdb",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Book Record",
                                link: "/book-record",
                                color: "teal",
                                id: "xzzjc",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Book Issue Record",
                                link: "/book-issue-record",
                                color: "teal",
                                id: "fp2ib",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Available Book List",
                                link: "/book-management",
                                color: "teal",
                                id: "c6xp79",
                                icon: "mdi-warehouse",
                            },
                        ],
                        id: "nzj5m",
                    },
                ],
                id: "1vtccp",
            },
            {
                name: "Inventory Module",
                icon: "mdi-cart",
                link: "/",
                color: "yellow",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Inventory Monitor",
                        link: "/inventory-monitor",
                        color: "yellow",
                        id: "slkr5n8",
                        icon: "mdi-monitor-eye",
                    },
                    {
                        name: "Sale Entry",
                        link: "/sale",
                        color: "yellow",
                        id: "qio1ie",
                        icon: "mdi-cart",
                    },
                    {
                        name: "Sale Record",
                        link: "/sale-record",
                        color: "yellow",
                        id: "dcp45",
                        icon: "mdi-store-plus",
                    },
                    {
                        name: "Purchase Entry",
                        link: "/purchase",
                        color: "yellow",
                        id: "m1qdi",
                        icon: "mdi-purse-outline",
                    },
                    {
                        name: "Purchase Record",
                        link: "/purchase-record",
                        color: "yellow",
                        id: "omkdqk",
                        icon: "mdi-purse",
                    },
                    {
                        name: "Purchase Return",
                        link: "/purchase-return",
                        color: "yellow",
                        id: "lal3on",
                        icon: "mdi-keyboard-return",
                    },
                    {
                        name: "Purchase Return Record",
                        link: "/purchase-return-record",
                        color: "yellow",
                        id: "yykuzjr",
                        icon: "mdi-clipboard-arrow-left",
                    },
                    {
                        name: "Product Stock",
                        link: "/stock",
                        color: "yellow",
                        id: "sv30u",
                        icon: "mdi-cart",
                    },
                    
                    /*
                    {
                        name: "Production Entry",
                        link: "/production",
                        color: "yellow",
                        id: "m8ihgq",
                        icon: "mdi-factory",
                    },
                    {
                        name: "Production List",
                        link: "/productions",
                        color: "yellow",
                        id: "p5nigh",
                        icon: "mdi-factory",
                    },
                    {
                        name: "Porduct Costing",
                        link: "/",
                        color: "yellow",
                        id: "eky88",
                        icon: "mdi-factory",
                    },
                    {
                        name: "Raw Materials",
                        link: "/material",
                        color: "yellow",
                        id: "slk5n8",
                        icon: "mdi-factory",
                    },
                    {
                        name: "Material Damage",
                        link: "/material-damage",
                        color: "yellow",
                        id: "slkr35n8",
                        icon: "mdi-factory",
                    },*/
                    {
                        name: "Settings",
                        icon: "mdi-hammer-screwdriver",
                        link: "/",
                        color: "yellow",
                        locked: true,
                        children: [
                            {
                                name: "Product Entry",
                                link: "/product",
                                color: "yellow",
                                id: "z1neiq",
                                icon: "mdi-cart-plus",
                            },
                            {
                                name: "Category Entry",
                                link: "/categories",
                                color: "yellow",
                                id: "rtjjfh",
                                icon: "mdi-view-grid-plus-outline",
                            },
                            {
                                name: "Unit Entry",
                                link: "/units",
                                color: "yellow",
                                id: "hjmtza",
                                icon: "mdi-grid-large",
                            },
                            {
                                name: "Supplier Entry",
                                link: "/supplier",
                                color: "yellow",
                                id: "hj80tza",
                                icon: "mdi-folder-swap",
                            },
                        ],
                        id: "ypa8n",
                    },
                    {
                        name: "Reports",
                        icon: "mdi-factory",
                        link: "/",
                        color: "yellow",
                        locked: true,
                        children: [
                            {
                                name: "Purchase Report",
                                link: "/purchase-report",
                                color: "yellow",
                                id: "z1neiq",
                                icon: "mdi-store-plus",
                            },
                            {
                                name: "Sale Report",
                                link: "/sale-report",
                                color: "yellow",
                                id: "rtjjfh",
                                icon: "mdi-store-plus",
                            },
                            {
                                name: "Supplier Due List",
                                link: "/supplier-due",
                                color: "yellow",
                                id: "hjmtza",
                                icon: "mdi-factory",
                            },
                            {
                                name: "Supplier Ledger",
                                link: "/supplier-ledger",
                                color: "yellow",
                                id: "6o4iea",
                                icon: "mdi-factory",
                            },
                            {
                                name: "Product Ledger",
                                link: "/product-ledger",
                                color: "yellow",
                                id: "bafqg",
                                icon: "mdi-factory",
                            },
                        ],
                        id: "ypaein",
                    },
                ],
                id: "nfkg6b",
            },
            {
                name: "Asset & Property Module",
                icon: "mdi-currency-bdt",
                color: "green",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Asset Entry",
                        link: "/asset-entry",
                        color: "green",
                        id: "5zak3n",
                        icon: "mdi-account-clock",
                    },
                    {
                        name: "Asset Sale",
                        link: "/asset-sale",
                        color: "green",
                        id: "o6uiuae",
                        icon: "mdi-account-clock",
                    },
                    {
                        name: "Depreciation Entry",
                        link: "/depreciation",
                        color: "green",
                        id: "o6uiua8e",
                        icon: "mdi-account-clock",
                    },
                    {
                        name: "Reports",
                        locked: true,
                        color: "green",
                        icon: "mdi-clipboard-list",
                        id: "twytv",
                        children: [
                            {
                                name: "Asset Record",
                                link: "/asset-record",
                                color: "green",
                                id: "4q3aob",
                                icon: "mdi-account-clock",
                            },
                            {
                                name: "Asset Sale Record",
                                link: "/asset-sale-record",
                                color: "green",
                                id: "1owb8f",
                                icon: "mdi-account-clock",
                            },
                            {
                                name: "Depreciation Record",
                                link: "/depreciation-record",
                                color: "green",
                                id: "1o81wb8f",
                                icon: "mdi-account-clock",
                            },
                        ],
                    },
                ],
                id: "c4nbkh",
            },
            {
                name: "Transport Module",
                icon: "mdi-car",
                link: "/",
                color: "amber",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Vehicle Entry",
                        link: "/vehicle-entry",
                        color: "amber",
                        id: "4rlowt",
                        icon: "mdi-car",
                    },
                    {
                        name: "Driver Entry",
                        link: "/driver-entry",
                        color: "amber",
                        id: "aypq3",
                        icon: "mdi-car",
                    },
                    {
                        name: "Position Entry",
                        link: "/positions",
                        color: "amber",
                        id: "ayp8q3",
                        icon: "mdi-car",
                    },
                    {
                        name: "Tour Entry",
                        link: "/tour",
                        color: "amber",
                        id: "ayp8q73",
                        icon: "mdi-car",
                    },
                    {
                        name: "Vehicle Member Entry",
                        link: "/vehicle-member",
                        color: "amber",
                        id: "a9yp8q73",
                        icon: "mdi-car",
                    },
                    {
                        name: "Reports",
                        icon: "mdi-clipboard-list",
                        link: "/",
                        color: "teal",
                        locked: true,
                        children: [
                            {
                                name: "Vehicle Record",
                                link: "/vehicle-record",
                                color: "teal",
                                id: "rl5xwt",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Driver Record",
                                link: "/driver-record",
                                color: "teal",
                                id: "xx5nn",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Tour Record",
                                link: "/tours",
                                color: "teal",
                                id: "x1x5nn",
                                icon: "mdi-warehouse",
                            },
                            {
                                name: "Vehicle Member Record",
                                link: "/vehicle-members",
                                color: "teal",
                                id: "x1Jx5nn",
                                icon: "mdi-warehouse",
                            },
                        ],
                        id: "fpft5n",
                    },
                ],
                id: "fpebel",
            },
            {
                name: "Administration",
                icon: "mdi-cog-outline",
                link: "/",
                color: "light-green",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Send SMS",
                        link: "/send-sms",
                        color: "light-green",
                        id: "1lshsm",
                        icon: "mdi-shield-account",
                    },
                    {
                        name: "Settings",
                        icon: "mdi-account-school-outline",
                        link: "/",
                        color: "light-green",
                        locked: true,
                        children: [
                            {
                                name: "Class Entry",
                                link: "/classes",
                                color: "light-green",
                                id: "k4roq",
                                icon: "mdi-home-plus-outline",
                            },
                            {
                                name: "Subject Entry",
                                link: "/subjects",
                                color: "light-green",
                                id: "ytlw2",
                                icon: "mdi-book-open-variant",
                            },
                            {
                                name: "Section Entry",
                                link: "/sections",
                                color: "light-green",
                                id: "kahlvt5",
                                icon: "mdi-air-filter",
                            },
                            {
                                name: "Session Entry",
                                link: "/sessions",
                                color: "light-green",
                                id: "kalvt5",
                                icon: "mdi-layers-search",
                            },
                            {
                                name: "Department Entry",
                                link: "/departments",
                                color: "light-green",
                                id: "4q3aob",
                                icon: "mdi-office-building-cog",
                            },
                            {
                                name: "Designation Entry",
                                link: "/designations",
                                color: "light-green",
                                id: "9vtlb",
                                icon: "mdi-school-outline",
                            },
                            // {
                            //     name: "Year Entry",
                            //     link: "/years",
                            //     color: "light-green",
                            //     id: "9vt39b",
                            //     icon: "mdi-calendar-multiple",
                            // },
                            {
                                name: "Month Entry",
                                link: "/months",
                                color: "light-green",
                                id: "9vt3gb",
                                icon: "mdi-calendar-month",
                            },
                            {
                                name: "Fees Head",
                                link: "/fees-head",
                                color: "light-green",
                                id: "9vt80b",
                                icon: "mdi-head-lightbulb-outline",
                            },
                            {
                                name: "Fees Setting",
                                link: "/fees-setting",
                                color: "light-green",
                                id: "w3h41b",
                                icon: "mdi-head-cog",
                            },
                            {
                                name: "Area Entry",
                                link: "/areas",
                                color: "light-green",
                                id: "4v3aob",
                                icon: "mdi-selection-marker",
                            },
                            {
                                name: "Country Entry",
                                link: "/countries",
                                color: "light-green",
                                id: "4v3codb",
                                icon: "mdi-earth-plus",
                            },
                            {
                                name: "Bank Type",
                                link: "/bank-type",
                                color: "light-green",
                                id: "4v3cob",
                                icon: "mdi-earth-plus",
                            },
                            {
                                name: "Board Entry",
                                link: "/boards",
                                color: "light-green",
                                id: "4v3c5ob",
                                icon: "mdi-earth-plus",
                            },
                            {
                                name: "Routine Entry",
                                link: "/routine",
                                color: "light-green",
                                id: "40xc5ob",
                                icon: "mdi-earth-plus",
                            },
                            {
                                name: "Period Entry",
                                link: "/period",
                                color: "light-green",
                                id: "st0xc5ob",
                                icon: "mdi-earth-plus",
                            },
                        ],
                        id: "t0kul7pv",
                    },
                    {
                        name: "Organization Profile",
                        link: "/basic-setting",
                        color: "light-green",
                        id: "3lshsm",
                        icon: "mdi-shield-account",
                    },
                    {
                        name: "Create User",
                        link: "/user",
                        color: "light-green",
                        id: "m5brd8",
                        icon: "mdi-account",
                    },
                    
                    {
                        name: "Branch Entry",
                        link: "/branches",
                        color: "light-green",
                        id: "9vtlpgb",
                        icon: "mdi-source-branch",
                    },
                    {
                        name: "Business Monitor",
                        link: "/business-monitor",
                        color: "light-green",
                        id: "w3h41bd",
                        icon: "mdi-monitor-account",
                    },
                    {
                        name: "Notice Entry",
                        link: "/notice",
                        color: "light-green",
                        id: "9ze4r9",
                        icon: "mdi-account-tie-outline",
                    },
                    {
                        name: "Holidays & Weekend",
                        link: "/holidays-weekend",
                        color: "light-green",
                        id: "tbixii",
                        icon: "mdi-account-tie-outline",
                    },
                    {
                        name: "Branchwise Attendance List",
                        link: "/attendance-list",
                        color: "light-green",
                        id: "yk4rf",
                        icon: "mdi-account-tie-outline",
                    },
                    
                ],
                id: "vzedf4",
            },
        ],
    }),

    getters: {
        menuItems(state) {
            return state.menuItems;
        }

    },

    mutations: {
        setMentItem(state, menuItem) {
            state.menuItems = menuItem
        }
    },

    actions: {

    }
}