export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            rooms: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        rooms(state) {
            return state.rooms.map((item, sl) => {
                item.sl = sl + 1;
                item.display_text = `${item.name} - ${item.room_no}`;
                item.status_text = item.status == 'a' ? 'Active' : 'Inactive'; 
                return item;
            })
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },

        setRoom(state, rooms) {
            state.rooms = rooms;
        }
    },

    actions: {
        async getRooms(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-rooms`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setRoom', res.data.rooms);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveRoom(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-room';
            } else {
                url = 'add-room';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getRooms');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteRoom(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-room/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getRooms');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async updateStatus(context, payload) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/change-room-status`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getRooms');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
    }
}