export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            vehicleLoading: false,
            tourLoading: false,
            drivers: [],
            vehicles: [],
            tours: [],
            members: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        vehicleLoading(state) {
            return state.vehicleLoading;
        },

        tourLoading(state) {
            return state.tourLoading;
        },

        drivers(state) {
            return state.drivers.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        vehicles(state) {
            return state.vehicles.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        tours(state) {
            return state.tours.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        members(state) {
            return state.members.map((item, sl) => {
                item.sl = sl + 1;
                item.display_name = item.type == 'Student' ? item.student.name : item.employee.name;
                return item;
            })
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setVehicleLoading(state, vehicleLoading) {
            state.vehicleLoading = vehicleLoading
        },

        setTourLoading(state, tourLoading) {
            state.tourLoading = tourLoading
        },

        setDriver(state, drivers) {
            state.drivers = drivers
        },

        setVehicle(state, vehicles) {
            state.vehicles = vehicles;
        },

        setTour(state, tours) {
            state.tours = tours;
        },

        setMember(state, members) {
            state.members = members
        }
    },

    actions: {
        async getDrivers(context, payload) {
            context.commit('setLoading', true);
            await axios.post(`${this.state.host}/get-drivers`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setDriver', res.data.drivers);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveDriver(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-driver';
            } else {
                url = 'add-driver';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getDrivers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteDriver(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-driver/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getDrivers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getVehicles(context, payload) {
            context.commit('setVehicleLoading', true);
            await axios.post(`${this.state.host}/get-vehicles`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setVehicle', res.data.vehicles);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setVehicleLoading', false);
        },

        async saveVehicle(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-vehicle';
            } else {
                url = 'add-vehicle';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getVehicles');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteVehicle(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-vehicle/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getVehicles');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getTours(context, payload) {
            context.commit('setTourLoading', true);
            await axios.post(`${this.state.host}/get-tours`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setTour', res.data.tours);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setTourLoading', false);
        },

        async saveTour(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-tour';
            } else {
                url = 'add-tour';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getTours');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteTour(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-tour/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getTours');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getMemberCode(context) {
            let newCode = await axios.get(`${this.state.host}/get-vehicle-member-code`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode;
        },

        async getMembers(context, payload) {
            await axios.post(`${this.state.host}/get-vehicle-members`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setMember', res.data.members);
            })
            .catch(error => this.dispatch('snackbar/error', error))
        },

        async saveMember(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-vehicle-member';
            } else {
                url = 'add-vehicle-member';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getMembers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteMember(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-vehicle-member/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getMembers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
    }
}