export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            assets: [],
            sales: [],
            depreciations: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        assets(state) {
            return state.assets.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        sales(state) {
            return state.sales.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        depreciations(state) {
            return state.depreciations.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },

        setAsset(state, assets) {
            state.assets = assets;
        },

        setSale(state, sales) {
            state.sales = sales;
        },

        setDepreciation(state, depreciations) {
            state.depreciations = depreciations
        }
    },

    actions: {
        async getAssets(context, payload) {
            context.commit('setLoading', true);

            await axios.post(`${this.state.host}/get-assets`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setAsset', res.data.assets);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveAsset(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-asset';
            } else {
                url = 'add-asset';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getAssets');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteAsset(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-asset/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getAssets');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getSales(context, payload) {
            context.commit('setLoading', true);

            await axios.post(`${this.state.host}/get-asset-sales`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setSale', res.data.sales);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveSale(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-asset-sale';
            } else {
                url = 'add-asset-sale';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSales');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteSale(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-asset-sale/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSales');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getDepreciations(context, payload) {
            await axios.post(`${this.state.host}/get-depreciations`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setDepreciation', res.data.depreciations);
            })
            .catch(error => this.dispatch('snackbar/error', error))
        },

        async saveDepreciation(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-depreciation';
            } else {
                url = 'add-depreciation';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteDepreciation(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-depreciation/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getDepreciations');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
    }
}