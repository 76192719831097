export default {
    namespaced: true,

    state: () => {
        return {
            loading         : false,
            transactions    : [],
            all_transactions: [],
            balances        : [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        transactions(state) {
            return state.transactions.map((item, sl) => {
                item.sl = sl + 1;
                item.withdraw = item.type == 'Withdraw' ? item.amount : 0.00;
                item.deposit = item.type == 'Deposit' ? item.amount : 0.00;
                return item;
            });
        },
        all_transactions(state) {
            return state.all_transactions.map((item, sl) => {
                item.sl = sl + 1;
                item.withdraw = item.type == 'Withdraw' ? item.amount : 0.00;
                item.deposit = item.type == 'Deposit' ? item.amount : 0.00;
                return item;
            });
        },
        balances(state) {
            return state.balances.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setTransactions(state, transactions) {
            state.transactions = transactions
        },
        setAllTransactions(state, transactions) {
            state.all_transactions = transactions
        },

        setBalances(state, balances) {
            state.balances = balances
        },
    },

    actions: {
        async getTransactions(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-pf-transactions`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setTransactions', res.data.transactions);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async getAllTransactions(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-pf-all-transactions`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setAllTransactions', res.data.transactions);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveTransaction(context, payload) {
            let response = {isSuccess: false}

            let url = '';
            if(payload.id != null) {
                url = 'update-pf-transaction'
            } else {
                url = 'add-pf-transaction'
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                response.isSuccess = true;
                response.transactionId = res.data.transactionId
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                response.isSuccess = false;
                this.dispatch('snackbar/error', error);
            })
            
            return response;
        },

        async deleteTransaction(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-pf-transaction/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getBalances(context, payload) {
            await axios.post(`${this.state.host}/get-pf-balances`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setBalances', res.data.balances);
            })
            .catch(error => this.dispatch('snackbar/error', error))
        },

        async getLedger(context, payload) {
            let ledger = await axios.post(`${this.state.host}/get-pf-ledger`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.ledger;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return ledger;
        }
    }
}