export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            stockLoading: false,
            books: [],
            stock: [],
            categories: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        stockLoading(state) {
            return state.stockLoading;
        },

        books(state) {
            return state.books.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        stock(state) {
            return state.stock.map((item) => {
                item.purchaseQuantity = item.book_details.reduce((p, c) => {return +p + +c.quantity}, 0).toFixed();
                item.issuedQuantity = item.issue_detail.reduce((p, c) => {return +p + +c.quantity}, 0).toFixed();
                return item;
            })
        },

        categories(state) {
            return state.categories.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },

        setStockLoading(state, stockLoading) {
            state.stockLoading = stockLoading;
        },

        setBook(state, books) {
            state.books = books;
        },

        setStock(state, stock) {
            state.stock = stock
        },

        setCategory(state, categories) {
            state.categories = categories
        }
    },

    actions: {
        async getBooks(context, payload) {
            context.commit('setLoading', true);

            await axios.post(`${this.state.host}/get-books`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setBook', res.data.books);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveBook(context, {book, details}) {
            let isSuccess = false;

            let url = '';

            if(book.id != 0) {
                url = 'update-book';
            } else {
                url = 'add-book';
                delete book.id;
            }

            let fd = new FormData();
            fd.append('book', JSON.stringify(book));
            fd.append('details', JSON.stringify(details));

            await axios.post(`${this.state.host}/${url}`, fd, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getBooks');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteBook(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-book/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getBooks');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getBookStock(context, payload) {
            context.commit('setStockLoading', true);

            await axios.post(`${this.state.host}/get-book-stock`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setStock', res.data.stock);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setStockLoading', false);
        },

        async getCategories(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-book-categories`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setCategory', res.data.categories);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveCategory(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-book-category';
            } else {
                url = 'add-book-category';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getCategories');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteCategory(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-book-category/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getCategories');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
    }
}